@font-face {
  font-family: "Aspira Light";
  src: local("Aspira Light"),
    url(./assets/fonts/AspiraLight.otf) format("opentype");
}

@font-face {
  font-family: "Aspira Bold";
  src: local("Aspira Bold"),
    url(./assets/fonts/AspiraBold.otf) format("opentype");
}

@font-face {
  font-family: "Aspira Thin";
  src: local("Aspira Thin"),
    url(./assets/fonts/AspiraThin.otf) format("opentype");
}

@font-face {
  font-family: "Aspira Demi";
  src: local("Aspira Demi"),
    url(./assets/fonts/AspiraDemi.otf) format("opentype");
}

@font-face {
  font-family: "Aspira Regular";
  src: local("Aspira Regular"),
    url(./assets/fonts/AspiraRegular.otf) format("opentype");
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.container {
  padding: 0rem 15vw;
}
.container-left {
  padding: 0 0 0rem 15vw;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0rem 7vw;
  }
  .container-left {
    padding: 0 0 0rem 7vw;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0rem 5vw;
  }
  .container-left {
    padding: 0 5vw 0rem 5vw;
  }
}